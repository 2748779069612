import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, Seo } from 'components';
import Header from 'components/Header';
import EmailForm from './components/EmailForm';
import { mobile } from 'styles/breakpoints';
import { Analytics } from 'apis/Analytics';
import { createLead, updateUser } from 'state/user/effects';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import Text from 'components/Text';
import { usePageView, useQuizData } from 'utils/hooks';
import { Events } from 'utils/events';
import { Quiz } from 'types/quiz';

const Container = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  max-width: 50%;
  flex-direction: column;

  @media ${mobile} {
    max-width: calc(100% - 2rem);
  }
`;

const Title = styled(Text)`
  font-weight: 400;
  text-align: center;
  strong {
    font-family: inherit;
    font-size: inherit;
    font-weight: 700;
  }
  margin-bottom: 0.75rem;
  @media ${mobile} {
    font-size: 1.25rem;
  }
`;

const Subtitle = styled(Text)`
  padding-bottom: 2.25rem;
  text-align: center;
  @media ${mobile} {
    width: 269px;
    font-size: 0.75rem;
    text-align: center;
  }
`;

const Footer = styled(Text)`
  text-align: center;
  @media ${mobile} {
    font-size: 0.75rem;
    margin-bottom: 1.5rem;
  }
`;

const ImageContainer = styled.div`
  margin: 4rem 0rem;
  width: 16rem;
  height: 16rem;

  @media ${mobile} {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const EmailFormStyled = styled(EmailForm)`
  margin-top: 1.5rem;
`;

const Email: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { quiz_answers, user } = useSelector((state: AppState) => state.user);
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;

  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;

  const dispatch = useDispatch();

  const { goToCheckout, goToResults } = useRouter();

  usePageView();

  const handleNextRoute = () => {
    if (variant === 'email-first') {
      return goToResults();
    }
    goToCheckout({ discount: false });
  };

  const handleGetResults = async (email: string) => {
    setError('');

    setLoading(true);

    Analytics.trackCTAButton(location.pathname);

    if (!user?.email || email !== user.email) {
      dispatch(dispatch(updateUser({ email, password: '' })));
    }

    quiz_answers.quiz = quiz;

    try {
      const code =
        (await dispatch(createLead({ email, funnel: quiz, quiz_answers }))) ??
        '';

      Events.checkoutPageVisit(code);

      handleNextRoute();
    } catch (error) {
      setError(error?.response.data?.errors?.email ?? '');

      setLoading(false);
    }
  };

  const data = useQuizData('email');

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo title="Effecto | Email" />
      <Header logoVariant="center" />
      <Container>
        <ContentContainer>
          <Title
            type="h1"
            color="dark100"
            dangerouslySetInnerHTML={{ __html: data?.title }}
          />
          <Subtitle type="body" color="dark80">
            {data?.inputLabel}
          </Subtitle>
          <EmailFormStyled
            loading={loading}
            error={error}
            onSubmit={handleGetResults}
            continueBtnTitle={data?.continueBtnTitle}
            placeholder={data?.inputPlaceholder}
          />
          <ImageContainer>
            <DynamicImage
              src={data.img}
              alt={data.alt}
              width="100%"
              height="100%"
            />
          </ImageContainer>

          <Footer type="body" color="dark60">
            {data?.footer}
          </Footer>
        </ContentContainer>
      </Container>
    </>
  );
};

export default Email;
